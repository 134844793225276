import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AddtagAddTagsComp: () => import('../../components/addtag/AddTagsComp.vue' /* webpackChunkName: "components/addtag-add-tags-comp" */).then(c => wrapFunctional(c.default || c)),
  Boundingboximage: () => import('../../components/boundingboximage/index.vue' /* webpackChunkName: "components/boundingboximage" */).then(c => wrapFunctional(c.default || c)),
  ButtonUploadInput: () => import('../../components/buttonUploadInput/ButtonUploadInput.vue' /* webpackChunkName: "components/button-upload-input" */).then(c => wrapFunctional(c.default || c)),
  Chart: () => import('../../components/chart/Chart.vue' /* webpackChunkName: "components/chart" */).then(c => wrapFunctional(c.default || c)),
  Confusionmatrix: () => import('../../components/confusionmatrix/index.vue' /* webpackChunkName: "components/confusionmatrix" */).then(c => wrapFunctional(c.default || c)),
  DatasetList: () => import('../../components/datasetList/index.vue' /* webpackChunkName: "components/dataset-list" */).then(c => wrapFunctional(c.default || c)),
  FilterSelector: () => import('../../components/filterSelector/FilterSelector.vue' /* webpackChunkName: "components/filter-selector" */).then(c => wrapFunctional(c.default || c)),
  InnerZoom: () => import('../../components/innerZoom/index.vue' /* webpackChunkName: "components/inner-zoom" */).then(c => wrapFunctional(c.default || c)),
  InsightMessage: () => import('../../components/insightMessage/index.vue' /* webpackChunkName: "components/insight-message" */).then(c => wrapFunctional(c.default || c)),
  ModelTest: () => import('../../components/modelTest/index.vue' /* webpackChunkName: "components/model-test" */).then(c => wrapFunctional(c.default || c)),
  Modellist: () => import('../../components/modellist/index.vue' /* webpackChunkName: "components/modellist" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderImage: () => import('../../components/placeholderImage/index.vue' /* webpackChunkName: "components/placeholder-image" */).then(c => wrapFunctional(c.default || c)),
  TrainModel: () => import('../../components/trainModel/index.vue' /* webpackChunkName: "components/train-model" */).then(c => wrapFunctional(c.default || c)),
  UploadDataset: () => import('../../components/uploadDataset/UploadDataset.vue' /* webpackChunkName: "components/upload-dataset" */).then(c => wrapFunctional(c.default || c)),
  VueLoadImage: () => import('../../components/vueLoadImage/index.vue' /* webpackChunkName: "components/vue-load-image" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
