export default function({ store, redirect }) {
  console.log("admin auth middleware");
  if (!store.state.auth.loggedIn) {
    return redirect("/auth/login");
  }
  if (
    store.state.auth.user &&
    store.state.auth.user.user &&
    store.state.auth.user.user.enabled
  ) {
    console.log("user active");
    if (store.state.auth.user.user.role != "admin") {
      if (store.state.auth.user.user.role != "superAdmin") {
        return redirect("/");
      }
    }
  } else {
    return redirect("/auth/logout");
  }
}
