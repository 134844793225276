//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    Component,
    Vue,
    Watch
} from 'nuxt-property-decorator'
@Component({
    middleware: ['authenticated'],
})

export default class Home extends Vue {
    loadingDeleteNotifications = false;
    showNotifications = false;
    notifications = [];
    visible = false;
    fetchNotificationsIn = false;
    menuItemSelected = [];
    @Watch('$store.state.page', {
        immediate: true,
        deep: true
    })
    onPageChanged(page) {
        this.menuItemSelected = [page]
    }
    created() {
        this.fetchNotifications();
        this.fetchNotificationsIn = setInterval(() => {
            this.fetchNotifications();
        }, 90000);
        console.log("created home", this.$route.name)

    }
    beforeDestroy() {
        try {
            clearInterval(this.fetchNotificationsIn)
            console.log("clearedInterval fetchNotificationsIn")
        } catch (error) {
            console.log(error)
        }
    }
    async fetchNotifications() {
        try {
            var res = await this.$axios.get(`${process.env.authURL}/notification?app=${process.env.APP_TYPE?process.env.APP_TYPE:'aistudio'}&vendor=${this.$auth.user.user.AssociatedVendor["vendorName"]}&user=${this.$auth.user.user.email}&limit=1000&sort=createdAt%20DESC`);
            this.notifications = res.data.results;
        } catch (error) {
            this.$message.error("Unable to fetch notifications, Network Error !");
        }
    }
    async deleteNotification(notification) {

        this.notifications.forEach(async (notf, notfIndex) => {
            if (notf.id == notification.id) {
                try {
                    this.loadingDeleteNotifications = true;
                    await this.$axios.delete(`${process.env.authURL}/notification/${notf.id}`)
                    this.notifications.splice(notfIndex, 1)
                    this.loadingDeleteNotifications = false;
                } catch (error) {
                    this.loadingDeleteNotifications = false;
                }

            }
        });
    }
    handleUserMenuClick = (action) => {
        if (action.key == 'logout') {
            this.$router.push("/auth/logout")
        }
        if (action.key == 'users') {
            this.$router.push("/auth/users")
        }
        if (action.key == 'videos') {
            this.$router.push("/auth/videos")
        }

        if (action.key == 'guide') {
            window.open('https://streamoid.gitbook.io/ai-studio/', '_newtab');
        }
    }
    handleTabMenuClick = (action) => {
        switch (action.key) {
            case "models":
                this.$router.push(`/image-tagging/projects/${this.$route.params.project}`)
                break;
            case "datasets":
                this.$router.push(`/image-tagging/projects/${this.$route.params.project}/datasets`)
                break;
            case "analysis":
                this.$router.push(`/image-tagging/projects/${this.$route.params.project}/analysis`)
                break;

            default:

                break;
        }
    }
    afterVisibleChange(val) {

    }
    showDrawer() {
        this.visible = true;
    }
    onClose() {
        this.visible = false;
    }
}
