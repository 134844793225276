export const state = () => ({
  page: "models",
  userStats: false
});
export const getters = {
  getCurrentPage: state => {
    return state.page;
  },
  getUserStats: state => {
    return state.userStats;
  }
};
export const actions = {
  SET_CURRENT_PAGE({ commit }, page) {
    commit("setCurrentPage", page);
  },
  SET_USER_STATS({ commit }, userStats) {
    commit("setUserStats", userStats);
  }
};
export const mutations = {
  setCurrentPage: (state, page) => {
    if (!process.server) {
      state.page = page;
    }
  },
  setUserStats: (state, userStats) => {
    if (!process.server) {
      state.userStats = userStats;
    }
  }
};
