import Vue from "vue";
import moment from "moment";
// import Antd from "ant-design-vue/lib";
import {
  Layout,
  Menu,
  Row,
  Col,
  Space,
  Dropdown,
  Icon,
  Button,
  Form,
  FormModel,
  List,
  Drawer,
  Input,
  InputNumber,
  Radio,
  Select,
  Card,
  Checkbox,
  Progress,
  Steps,
  Tag,
  Spin,
  Alert,
  AutoComplete,
  Pagination,
  Popconfirm,
  Tabs,
  Empty,
  Tooltip,
  Divider,
  Upload,
  Modal,
  Table,
  Badge,
  Skeleton,
  message
} from "ant-design-vue";
// import { Menu } from "ant-design-vue";
Vue.use(Pagination);
Vue.use(Skeleton);
Vue.use(Table);
Vue.use(Empty);
Vue.use(Tabs);
Vue.use(Divider);
Vue.use(AutoComplete);
Vue.use(Alert);
Vue.use(Spin);
Vue.use(Tag);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(Card);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Layout);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Drawer);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Col);
Vue.use(Row);
Vue.use(Space);
Vue.use(Badge);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(Button);
Vue.use(List);
Vue.use(Steps);
Vue.use(Tooltip);
Vue.use(Popconfirm);
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$error = Modal.error;

Vue.prototype.$convertToSlug = function(Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};
Vue.prototype.$convertToDate = function(value) {
  var formatedDate = "-";
  try {
    formatedDate =
      moment(String(value)).format("DD/MMM/YY") == "Invalid date"
        ? "-"
        : moment(String(value)).format("DD/MMM/YY");
  } catch (error) {
    return "-";
  }
  return formatedDate;
};

Vue.prototype.$getEvalColors = function(value) {
  var ecolor = "#1D9CED";
  try {
    var cols = ["#1D9CED", "#39CB89", "#5D3EFF"];
    if (value < cols.length) {
      ecolor = cols[value];
    }
  } catch (error) {
    return "#000000";
  }
  return ecolor;
};

Vue.prototype.$roundOffPercent = function(value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
};

Vue.prototype.$copyText = text => {
  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      document.body.removeChild(textArea);
      return Promise.resolve();
    } catch (err) {
      document.body.removeChild(textArea);
      return Promise.reject(err);
    }
  }

  return navigator.clipboard.writeText(text);
};
Vue.prototype.$convertToDateTime = function(value) {
  var formatedDate = "-";
  try {
    formatedDate =
      moment(String(value)).format("DD/MMM/YYYY HH:MM A") == "Invalid date"
        ? "-"
        : moment(String(value)).format("DD/MMM/YYYY HH:MM A");
  } catch (error) {
    return "-";
  }
  return formatedDate;
};
//Vue.use(Antd);
// Base,
//   Affix,
//   Anchor,
//   AutoComplete,
//   Alert,
//   Avatar,
//   BackTop,
//   Badge,
//   Breadcrumb,
//   Button,
//   Calendar,
//   Card,
//   Collapse,
//   Carousel,
//   Cascader,
//   Checkbox,
//   Col,
//   DatePicker,
//   Divider,
//   Dropdown,
//   Form,
//   FormModel,
//   Icon,
//   Input,
//   InputNumber,
//   Layout,
//   List,
//   LocaleProvider,
//   Menu,
//   Mentions,
//   Modal,
//   Pagination,
//   Popconfirm,
//   Popover,
//   Progress,
//   Radio,
//   Rate,
//   Row,
//   Select,
//   Slider,
//   Spin,
//   Statistic,
//   Steps,
//   Switch,
//   Table,
//   Transfer,
//   Tree,
//   TreeSelect,
//   Tabs,
//   Tag,
//   TimePicker,
//   Timeline,
//   Tooltip,
//   Upload,
//   Drawer,
//   Skeleton,
//   Comment,
//   // ColorPicker,
//   ConfigProvider,
//   Empty,
//   Result,
//   Descriptions,
//   PageHeader,
//   Space,
