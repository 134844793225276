export default function({ store, redirect }) {
  //   console.log("auth middleware", store.state.auth);
  if (!store.state.auth.loggedIn) {
    return redirect("/auth/login");
  }
  if (
    store.state.auth.user &&
    store.state.auth.user.user &&
    store.state.auth.user.user.enabled
  ) {
    console.log("user active");
  } else {
    return redirect("/auth/logout");
  }
}
