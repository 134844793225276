import { render, staticRenderFns } from "./projects.vue?vue&type=template&id=105b1890&scoped=true&"
import script from "./projects.vue?vue&type=script&lang=js&"
export * from "./projects.vue?vue&type=script&lang=js&"
import style0 from "./projects.vue?vue&type=style&index=0&id=105b1890&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105b1890",
  null
  
)

export default component.exports